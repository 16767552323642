var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('Header',{attrs:{"search-query":_vm.searchQuery,"search-placeholder":_vm.searchPlaceholder,"search-list":_vm.searchList,"is-logged-in":_vm.isLoggedIn,"is-completed":_vm.is_completed,"redirect-to-parent-dashboard-flag":_vm.redirectToParentDashboardFlag,"select-student-modal-flag":_vm.selectStudentModalFlag,"campaign":_vm.campaign},on:{"input":function($event){_vm.searchQuery = $event},"item-click":_vm.searchItemClickHandler,"sign-in":() => _vm.toggleParentSignUpModal({ show: true }),"register":_vm.registerStudentHandler,"dashboard":_vm.goToDashboardHandler,"switch-student":() => _vm.toggleSelectStudentModal({ show: true }),"logout":_vm.onClickLogoutHandler}}),_c('v-main',{staticClass:"d-flex"},[_c('div',{staticClass:"img_container"},[_c('img',{class:[
              'imageBarBottom',
              { mobile: _vm.$vuetify.breakpoint.smAndDown },
            ],style:(_vm.imgStyles),attrs:{"src":_vm.imageSrc}})]),(_vm.campaign.campaign_detail)?_c('div',{staticStyle:{"padding":"10px"}},[(_vm.is_completed)?_c('campaign-completed'):_c('public-donation-page')],1):_vm._e()]),_c('Footer',{staticStyle:{"width":"100vw"}}),_c('make-donation-modal'),_c('thank-you-modal'),(_vm.campaignDetails)?_c('share-fundraiser-modal'):_vm._e(),_c('parent-sign-up-modal'),_c('parent-verify-email-modal'),_c('reset-password-modal'),(_vm.selectStudentModalFlag)?_c('select-student-modal'):_vm._e(),_c('create-student-modal',{on:{"openAgain":_vm.openStudentCreate}}),(
          this.$route.name == 'publicParentDashboard' &&
          !this.alertmessagepopupshow
        )?_c('alert-message-modal'):_vm._e(),_c('invite-via-mail'),_c('invite-via-sms')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<!-- Footer.vue -->
<template>
  <v-footer dark padless v-if="!is_completed" class="footer no-theme theme--none d-flex">
    <!-- Top section -->
    <v-card theme="none" class="footer-card py-10" width="100%">
      <v-card-text class="px-0">
        <v-container>
          <v-row class="footer-row">
            <!-- Footer Left: Logo and Description -->
            <v-col cols="12" sm="4" class="footer-left">
              <v-row>
                <v-col cols="12" sm="10">
                  <div class="logo-container">
                    <img
                      src="@/assets/publicDonationPage/wizardprimarylogo.svg"
                      alt="Logo"
                      class="footer-logo"
                    />
                  </div>
                  <div class="footer-description">
                    WizFit Challenge is an initiative by The World Famous Harlem
                    Wizards
                  </div>
                </v-col>
                <v-col
                  cols="2"
                  v-if="$vuetify.breakpoint.smAndUp"
                  class="divider-col"
                >
                  <div class="vertical-divider"></div>
                </v-col>
              </v-row>
            </v-col>

            <!-- Footer Center: Useful Links -->
            <v-col cols="12" sm="4" class="footer-center">
              <v-row justify="center">
                <v-col cols="12" sm="8">
                  <div class="footer-links mt-8">
                    <div class="footer-links-title">Useful links</div>
                    <div class="links-underline"></div>
                    <br /><br />
                    <a href="mailto:harlemwizards.com" class="footer-link">
                      About us
                    </a>
                    <div class="footer-link">Privacy policy</div>
                    <div class="footer-link">Terms &amp; Conditions</div>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <!-- Footer Right: Need Help & Social Icons -->
            <v-col cols="12" sm="4" class="d-flex footer-right">
              <v-row justify="center">
                <v-col cols="12" sm="8">
                  <div class="footer-links  mt-8">
                    <div class="footer-links-title">Need Help</div>
                    <div class="links-underline"></div>
                    <br /><br />
                    <div class="info-column ">
                    <div class="d-flex ">
                      <img
                        src="@/assets/publicDonationPage/message.svg"
                        alt="Message Icon"
                        class="contact-img"
                      />
                      <div class="contact-text">
                        <a
                          href="mailto:support@harlemwizardsinabox.com"
                          class="d-flex contact-text"
                        >
                          support@harlemwizardsinabox.com
                        </a>
                      </div>
                    </div>
                    <br />
                    <div class="d-flex ">
                      <img
                        src="@/assets/publicDonationPage/location.svg"
                        alt="Location Icon"
                        class="contact-img"
                      />
                      <div class="d-flex contact-text">
                        Harlem Wizards Entertainment Basketball<br />
                        330 Fairfield Rd.<br />
                        Fairfield, NJ 07004
                      </div>
                    </div></div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <!-- Bottom section -->
    <v-card width="100%" class="footer-bottom-card">
      <v-divider color="#FFBA00"></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="4" v-if="$vuetify.breakpoint.smAndUp">
              <div class="footer-paragraph">
                Copyright © 2025 Harlem Wizards
              </div>
            </v-col>
            <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
            <v-col
              cols="12"
              sm="4"
              :class="
                $vuetify.breakpoint.smAndUp ? 'social-links' : 'social-links-xs'
              "
            >
              <a href="https://twitter.com/HarlemWizards">
                <img
                  class="footer-icon"
                  src="@/assets/publicDonationPage/twitter.svg"
                  alt="Twitter"
                />
              </a>
              <a href="https://www.facebook.com/TheHarlemWizards">
                <img
                  class="footer-icon"
                  src="@/assets/publicDonationPage/facebook.svg"
                  alt="Facebook"
                />
              </a>
              <a href="https://tiktok.com/@harlemwizards">
                <img
                  class="footer-icon"
                  src="@/assets/publicDonationPage/tiktok.svg"
                  alt="TikTok"
                />
              </a>
              <a href="https://www.instagram.com/worldfamousharlemwizards/">
                <img
                  class="footer-icon"
                  src="@/assets/publicDonationPage/instagram.svg"
                  alt="Instagram"
                />
              </a>
              <a href="https://www.youtube.com/user/HarlemWizards">
                <img
                  class="footer-icon"
                  src="@/assets/publicDonationPage/youtube.svg"
                  alt="YouTube"
                />
              </a>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-if="$vuetify.breakpoint.xsOnly"
            >
              <div class="footer-paragraph">
                Copyright © 2025 Harlem Wizards
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    is_completed: Boolean,
  },
};
</script>

<style scoped>
.v-sheet.v-card {
    border-radius: 0px;
}


.no-theme.theme--light,
.no-theme.theme--dark {
  background-color: transparent !important;
  color: inherit !important;
}
.footer {
  background-color: #2c1963 !important;
  width: 100vw;
  color: white !important;
}

.v-application a {
    color: white;
}

.footer-card {
  background-color: #2c1963 !important;
}

.footer-bottom-card {
  background-color: #180943 !important;
}

.footer {
  /* background-color: #2c1963 !important; */
  width: 100vw;
}

.footer-card {
  background-color: #2c1963;
  justify-content: center !important;

}

.footer-bottom-card {
  background-color: #180943;
}

.footer-row {
  padding-top: 20px;
}

/* Left Section */
.logo-container {
  text-align: center;
}
.footer-logo {
  max-width: 100%;
  height: auto;
}
.footer-description {
  font-family: Roboto Slab;
    font-style: normal;
    text-align: center;
    overflow-wrap: break-word;
    font-weight: 700;
    font-size: 20px;
    line-height: 161.5%;
    color: #fff;
    margin-top: 40px;
    margin-bottom: 40px;
}

/* Vertical Divider */
.divider-col .vertical-divider {
  border-left: 2px solid white;
  height: 100%;
  margin: auto;
}

/* Center & Right Sections */
.footer-links-title {
  font-weight: 700;
  font-size: 22px;
  font-family: "Roboto Slab", serif;
  line-height: 30px;
  letter-spacing: 1px;
  place-self: center;
  text-align: center;
  color: white;
}
.links-underline {
  border-bottom: 5px solid #ffba00;
  width: 70px;
  margin: auto;
  text-align: center;
}
.footer-link {
  color: white;
  text-decoration: none;
  display: block;
  text-align: center;
  gap: 10px;
  margin: 10px 0;
  text-align: center;
  line-height: 40px;
}

/* Need Help Section */
.info-column {
  display: grid;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
}
.contact-img {
  max-width: 40px;
  margin-right: 10px;
}

.contact-text {
  word-break: break-word; 
  overflow-wrap: break-word;
  max-width: 100%;
  white-space: normal; 
  color:white;
  display: block;}

/* Bottom Section */
.footer-paragraph {
  color: white;
  text-align: justify;
  font-size: 14px;
}

.footer-center {
  justify-content: center !important;
  width: max-content;
  font-size: 20px;
  font-weight: 500;
}
/* Social Icons */
.social-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.social-links-xs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-icon {
  max-width: 30px;
  margin: 0 5px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .footer-row {
    padding-top: 10px;
  }
  .footer-links-title {
    font-size: 18px;
  }
  .footer-link {
    font-size: 18px;
  }
  .footer-paragraph {
    text-align: center;
  }
  .footer-right{
  text-align: justify;

  }
}
.footer-right {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;

}
.footer-center {
  justify-content: center !important;
  width: max-content;
  font-size: 20px;
  font-weight: 500;
}

</style>

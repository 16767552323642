<template>
  <div class="header-wrapper">
      <!-- LEFT: Logo & Title -->
      <div class="header-left">
        <img class="header-logo" :src="require('@/assets/publicDonationPage.png')" alt="WizFit Logo" />
        <h1 class="header-title">Welcome To WizFit Challenge</h1>
      </div>

      <!-- CENTER: Search Input (responsive) -->
      <div class="header-search" >
           
        <!-- <i v-if="isMobile" class="mdi mdi-close close-search-icon" @click="showMobileSearch = false"></i> -->
        <div class="search-container">
          <i class="mdi mdi-magnify search-icon"></i>
          <input
            class="search-input"
            :value="searchQuery"
            @input="$emit('input', $event.target.value)"
            :placeholder="searchPlaceholder"
          />
        </div>
        <div v-if="searchQuery" class="search-results">
          <div
            v-for="(item, index) in searchList"
            :key="index"
            class="search-result-item"
            @click="$emit('item-click', item)"
          >
            <div class="search-result-avatar">
              <img v-if="item.profile_pic" :src="item.profile_pic" alt="pic" />
              <span v-else class="avatar-initials">{{ getInitials(item.name) }}</span>
            </div>
            <div class="search-result-details">
              <strong>{{ item.name }}</strong>
              <span class="user-type">{{ item.user_type }}</span>
              <div class="secondary-text" v-if="item.user_type === 'student'" v-html="item.grade_teacher + ' (Grade ' + item.grade_title + ')' "></div>
              <div class="secondary-text" v-if="item.school_name">{{ item.school_name }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- RIGHT: Auth Buttons / Dashboard -->
      <div class="header-right">
        <div v-if="!isLoggedIn" class="header-actions">
          <button class="btn sign-in-btn" @click="$emit('sign-in')">Sign In</button>
          <button style="background-color: red; color:white ;" class="btn register-btn" @click="$emit('register')">Register Student</button>
        </div>
        <div v-else class="header-actions">
          <button class="btn" v-if="redirectToParentDashboardFlag" @click="$emit('dashboard')">Dashboard</button>
          <button class="btn" v-if="selectStudentModalFlag" @click="$emit('switch-student')">Switch Student</button>
          <button class="btn logout-btn" @click="$emit('logout')">Logout</button>
        </div>

    
      </div>

    <!-- Progress bar -->
    <v-progress-linear
      :active="!campaign.campaign_detail"
      :indeterminate="!campaign.campaign_detail"
      absolute
      bottom
      color="#FFBA00"
    />
  </div>
</template>

<style scoped>
.header-wrapper {
  width: 100%;
  background: linear-gradient(90deg, #2c1963, #3b2a79);
  color: white;
  font-family: "Lato", sans-serif;
  position: relative;
  align-items: center;
  display: grid;
  height: 10vh;
  grid-template-columns: 2fr 2fr 1fr;
}


.header-left {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex-shrink: 0;
}

.header-logo {
  max-height: 100%;
  width: 100px;
  height: auto;
}
.search-input::placeholder {
  color: white; 
  opacity: 1; 
}
.header-title {
  font-size: 2vw;
  font-weight: 600;
  margin: 0;
}
.search-icon {
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  font-size: 18px;
  pointer-events: none;
  z-index: 2;
}

.header-search {
  flex: 1;
  max-width: 500px;
  width: auto;
  position: relative;
  display: flex;
  align-self: center;
  flex-direction: row;
}
.search-container {
  position: relative;
  width: 100%;
  background-color: #51417d;
  padding: 10px;
  border-radius: 9px;
  z-index: 1;
  color: white;
}
.search-input {
  width: 100%;
  padding: 0.5rem 2rem 0.5rem 2.5rem; /* extra left padding for icon */
  border-radius: 9px;
  border: 2px solid transparent;
  font-size: 14px;
  outline: none;
  position: relative;
  z-index: 1;
}

.close-search-icon {
  align-self: flex-end;
  margin-bottom: 0.25rem;
  cursor: pointer;
}

.search-results {
  max-height: 320px;
  height: auto;
  overflow: hidden;
  overflow-y: scroll;
  background: #fff;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  margin-top: 0.5rem;
  width: 100%;
  z-index: 20;
}

.search-result-item {
  display: flex;
  padding: 0.75rem;
  gap: 0.75rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.search-result-item:hover {
  background: #f8f8f8;
}

.search-result-avatar img,
.avatar-initials {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  background: #6f42c1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.search-result-details {
  display: flex;
  flex-direction: column;
}

.user-type {
  font-size: 12px;
  color: #0085ff;
}

.secondary-text {
  font-size: 12px;
  color: #666;
}

/* Right Section */
.header-right {
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 1rem;
  flex-shrink: 1;
}

.header-actions {
  display: flex;
  gap: 1.5rem;
}

.btn {
  background: #ffba00;
  color: #2c1963;
  max-height: 55px;
  width: 100%;
  border: none;
  padding: 0.5rem 1.25rem;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  font-size: 18px;
}

.btn.logout-btn {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.mobile-search-icon {
  font-size: 20px;
  cursor: pointer;
  display: none;
}

/* Responsive */
@media (max-width: 1000px) {
.header-title[data-v-20f42763] {
    font-size: 5vw;
    font-weight: 600;
    margin: 0;
}
.header-logo {
  max-height: 100%;
  height: auto;
}
  .header-wrapper {
    width: 100%;
    background: linear-gradient(90deg, #2c1963, #3b2a79);
    color: white;
    font-family: "Lato", sans-serif;
    position: relative;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr; /* Single column layout */
    align-items: center;
    justify-items: center;
    text-align: center;
    padding: 1rem;
    gap: 1rem;
    height: auto; /* Let content dictate height */
  }
  .header-left,
  .header-right {
    justify-content: center;
    width: 100%;
  }

  .mobile-search-icon {
    display: flex;
  }

  .header-search {
    width: 100%;
  }

  .btn {
    padding: 0.5rem 1rem;
    font-size: 16px;
  }
}
</style>


<script>
import { mapActions } from "vuex";
import Helper from "@/helper";

export default {
  name: "PublicHeader",
  props: {
  searchQuery: String,
  searchList: Array,
  searchPlaceholder: String,
    is_completed: {
      type: Boolean,
      default: false,
    },
    isLoggedIn: {
      type: Boolean,
      default: 
      false,
    },
    redirectToParentDashboardFlag: {
      type: Boolean,
      default: false,
    },
    selectStudentModalFlag: {
      type: Boolean,
      default: false,
    },
    campaign: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      searchLoading: false,
      showMobileSearch: false, // toggles mobile search expansion
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isParentPage(){
      return false
    }
  },
  watch: {
    searchQuery() {
      this.searchLoading = true;
      this.debounceSearch;
    },
  },
  mounted() {
  console.log("🧭 redirectToParentDashboardFlag on mount:", this.redirectToParentDashboardFlag);
},
  methods: {
    ...mapActions({
      toggleParentSignUpModal: "publicDonationPage/toggleParentSignUpModal",
      updateUserDetails: "publicDonationPage/updateUserDetails",
      toggleSelectStudentModal: "publicDonationPage/toggleSelectStudentModal",
    }),

  

    searchItemClickHandler(item) {
      console.log("Search item clicked:", item);
      Helper.openPublicDonationPage("", `${item.user_type}=${item.id}`);
    },
    getInitials(name) {
      if (!name) return "";
      const parts = name.split(" ");
      if (parts.length > 1) {
        return (parts[0][0] + parts[parts.length - 1][0]).toUpperCase();
      } else {
        return name.substring(0, 2).toUpperCase();
      }
    },
  },
};
</script>

<style>
.v-application--wrap {
  flex: 1 1 auto;
  display: flex;
  position: relative;
}

.v-toolbar__title {
  white-space: normal !important;
}
</style>
<template>
  <div>
    <v-container fluid class="pa-0 ma-0">
      <Header
      :search-query="searchQuery"
  :search-placeholder="searchPlaceholder"
  :search-list="searchList"
  @input="searchQuery = $event"
  @item-click="searchItemClickHandler"
  @sign-in="() => toggleParentSignUpModal({ show: true })"
  @register="registerStudentHandler"
  @dashboard="goToDashboardHandler"
  @switch-student="() => toggleSelectStudentModal({ show: true })"
  @logout="onClickLogoutHandler"
  :is-logged-in="isLoggedIn"
  :is-completed="is_completed"
  :redirect-to-parent-dashboard-flag="redirectToParentDashboardFlag"
  :select-student-modal-flag="selectStudentModalFlag"
  :campaign="campaign"
/>
      <v-main class="d-flex">
        <div class="img_container">
          <!-- Show mobile image if xsOnly is true -->
          <img
            :src="imageSrc"
            :class="[
              'imageBarBottom',
              { mobile: $vuetify.breakpoint.smAndDown },
            ]"
            :style="imgStyles"
          />
        </div>

        <div style="padding: 10px" v-if="campaign.campaign_detail">
          <campaign-completed v-if="is_completed"></campaign-completed>
          <public-donation-page v-else></public-donation-page>
        </div>
      </v-main>
      <Footer style="width: 100vw" />
      <make-donation-modal></make-donation-modal>
      <thank-you-modal></thank-you-modal>
      <share-fundraiser-modal v-if="campaignDetails"></share-fundraiser-modal>
      <parent-sign-up-modal></parent-sign-up-modal>
      <parent-verify-email-modal></parent-verify-email-modal>
      <reset-password-modal></reset-password-modal>
      <select-student-modal
        v-if="selectStudentModalFlag"
      ></select-student-modal>
      <create-student-modal
        @openAgain="openStudentCreate"
      ></create-student-modal>
      <!-- <one-time-video-modal
      style="margin-top: -5660px"
      v-if="this.$route.name == 'publicParentDashboard'"
    ></one-time-video-modal> -->
      <alert-message-modal
        v-if="
          this.$route.name == 'publicParentDashboard' &&
          !this.alertmessagepopupshow
        "
      ></alert-message-modal>
      <invite-via-mail></invite-via-mail>
      <invite-via-sms></invite-via-sms>
    </v-container>
  </div>
</template>

<script>
import Axios from "@/api/BaseAxios";
import Header from "./Header.vue"
import {
  API_PUBLIC_GET_CAMPAIGN_DETAILS,
  API_ADMIN_STUDENT_MANAGEMENT_GET,
  API_PUBLIC_MASTER_SEARCH,
} from "@/constants/APIUrls";
// import { ROUTER_URL } from "@/constants/urls";
import {
  SUB_DOMAIN,
  CAMPAIGN_ID,
  ID,
  CAMPAIGN_DETAIL,
  SEARCH,
} from "@/constants/APIKeys";
import { mapActions, mapGetters } from "vuex";
import Helper from "@/helper";

import _ from "lodash";
import { SCHOOL, STUDENT, TEACHER } from "@/constants/ModuleKeys";
import authToken from "@/common/authToken";
import { ROUTER_URL } from "@/constants/urls";
import Footer from "./Footer.vue";
export default {
  name: "PublicDonationPageLayout",
  components: {
    Header,
    Footer,
    CreateStudentModal: () =>
      import(
        "@/components/PublicDonationPages/CreateStudentModal/CreateStudentModal"
      ),
    SelectStudentModal: () =>
      import(
        "@/components/PublicDonationPages/SelectStudentModal/SelectStudentModal"
      ),
    ResetPasswordModal: () =>
      import(
        "@/components/PublicDonationPages/ResetPasswordModal/ResetPasswordModal"
      ),
    ParentVerifyEmailModal: () =>
      import(
        "@/components/PublicDonationPages/ParentVerifyEmailModal/ParentVerifyEmailModal.vue"
      ),
    ParentSignUpModal: () =>
      import(
        "@/components/PublicDonationPages/ParentSignUpModal/ParentSignUpModal.vue"
      ),
    ShareFundraiserModal: () =>
      import(
        "@/components/PublicDonationPages/ShareFundraiserModal/ShareFundraiserModal.vue"
      ),
    ThankYouModal: () =>
      import(
        "@/components/PublicDonationPages/ThankYouModal/ThankYouModal.vue"
      ),
    MakeDonationModal: () =>
      import(
        "@/components/PublicDonationPages/MakeDonationModal/MakeDonationModal"
      ),
    PublicDonationPage: () =>
      import("@/views/PublicDonationPage/PublicDonationPage.vue"),
    inviteViaMail: () =>
      import(
        "@/components/PublicDonationPages/InviteViaMailModal/InviteViaMailModal"
      ),
    inviteViaSms: () =>
      import(
        "@/components/PublicDonationPages/InviteViaMailModal/InviteViaSmsModal"
      ),
    /**  OneTimeVideoModal: () =>
      import(
        "@/components/PublicDonationPages/OneTimeVideoModal/OneTimeVideoModal"
      ),*/
    AlertMessageModal: () =>
      import(
        "@/components/PublicDonationPages/AlertMessageModal/AlertMessageModal"
      ),
    CampaignCompleted: () =>
      import("@/views/PublicDonationPage/CampaignCompleted/CampaignCompleted"),
  },
  data() {
    return {
      loading: true,
      showSelectStudentModal: false,
      searchQuery: "",
      searchList: [],
      searchLoading: false,
      searchPlaceholder: "Search Students, Schools, Teachers",
      videoflag: false,
      videowatched: localStorage.getItem("is_watched"),
      isvideo: false,
      count: 1,
      campaign_status: "",
      alertmessagepopupshow: "",
      lang: "en",
      languageList: [
        { name: "English", value: "en" },
        { name: "Spanish", value: "es" },
      ],
    };
  },
  computed: {
    imageSrc() {
      console.log("🔍 userDetails inside selectStudentModalFlag:", this.userDetails);
      return this.$vuetify.breakpoint.smAndDown
        ? require("@/assets/publicDonationPage/publicDonation_mobile.png")
        : require("@/assets/publicDonationPage/publicDonation.svg");
    },
    imgStyles() {
      return {
        width: "100%",
        height: "auto",
        objectFit: "cover",
      };
    },
    ...mapGetters({
      campaign: "publicDonationPage/getCampaign",
      selectedCampaign: "campaign/getSelectedCampaign",
      userDetails: "publicDonationPage/getUserDetails",
    }),
    campaignDetails() {
      return this.campaign.campaign_detail;
    },
    isLoggedIn() {
  return Boolean(this.userDetails && !_.isEmpty(this.userDetails));
},
    is_completed() {
      if (this.campaign_status === "Completed") {
        return true;
      } else {
        return false;
      }
    },
  
  
    selectStudentModalFlag() {
      let flag = false;
      if (
        !_.isEmpty(this.userDetails) &&
        this.$route.name === ROUTER_URL.publicParentDashboard.name
      )
        flag = true;
      return flag;
    },
    redirectToParentDashboardFlag() {
      console.log("🟡 current parent route:", this.$route.name);
      let flag = false;
      if (
        !_.isEmpty(this.userDetails) &&
        this.$route.name === ROUTER_URL.publicDonationPages.name
      )
        flag = true;
      return flag;
    },
    studentID() {
      return this.$route.query[STUDENT];
    },
    routesName() {
      return this.$route.name;
    },
  },
  watch: {
    userDetails: {
    handler(newVal) {
      this.showSelectStudentModal =
        !_.isEmpty(newVal) &&
        this.$route.name === ROUTER_URL.publicParentDashboard.name;
    },
    immediate: true,
    deep: true,
  },
    //Watcher for detecting change in SEARCH
    searchQuery: function () {
      this.searchLoading = true;
      this.debounceGetCampaignData();
    },
    // routesName: function () {
    //   location.reload();
    // },
    studentID: function () {
      location.reload();
    },
    selectedCampaign: {
      handler(newCampaign) {
        console.log("Selected Campaign Updated:", newCampaign);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      showToast: "snackBar/showToast",
      updateCampaign: "publicDonationPage/updateCampaign",
      toggleMakeDonationModal: "publicDonationPage/toggleMakeDonationModal",
      toggleThankYouModal: "publicDonationPage/toggleThankYouModal",
      toggleParentSignUpModal: "publicDonationPage/toggleParentSignUpModal",
      updateUserDetails: "publicDonationPage/updateUserDetails",
      toggleSelectStudentModal: "publicDonationPage/toggleSelectStudentModal",
      toggleCreateStudentModal: "publicDonationPage/toggleCreateStudentModal",
      // toggleOneTimeVideoModal: "publicDonationPage/toggleOneTimeVideoModal",
      toggleAlertMessageModal: "publicDonationPage/toggleAlertMessageModal",
    }),
    registerStudentHandler() {
      if (this.isLoggedIn) {
        this.toggleCreateStudentModal({ show: true, actionType: "add" });
      } else {
        this.toggleParentSignUpModal({ show: true, signUpPageFlag: true });
      }
    },
    getCampaignDetails() {
      const _this = this;
      this.$i18n.locale = this.lang; // Set the locale if not already set
      let type = this.publicDonationPageType();
      const successHandler = (res) => {
        this.campaignId = res.data.campaign_detail.id;
        // this.$store.dispatch("campaign/updateSelectedCampaign", res.data);
        this.campaign_status = res.data.campaign_detail.campaign_status;
        let {
          campaign_assests_list,
          campaign_detail,
          school_list,
          district_detail,
          school_detail,
          teacher_detail,
          student_detail,
          is_payout_done,
          student_assests_list,
          teacher_assests_list,
        } = res.data;
        _this.updateCampaign({
          type,
          campaign_assests_list,
          campaign_detail,
          school_list,
          district_detail,
          school_detail,
          teacher_detail,
          student_detail,
          student_assests_list,
          teacher_assests_list,
          is_payout_done,
        });
        if (this.$route.query.donation_id && this.$route.query.payment_status) {
          this.toggleMakeDonationModal({ show: false });
          this.toggleThankYouModal({ show: true });
          return;
        }
        if (
          this.isLoggedIn &&
          this.$route.name === ROUTER_URL.publicDonationPages.name
        ) {
          // _this.$router.push({
          //   name: ROUTER_URL.publicParentDashboard.name,
          // });
        }
        _this.loading = false;
      };
      const failureHandler = (res) => {
        _this.showToast({ message: "Campaign does not exist!", color: "e" });
        // setTimeout(function(){ window.location.replace("http://www.w3schools.com"); }, 3000);
        console.log("Failure: ", res);
      };
      let formData = {};
      formData[SUB_DOMAIN] = window.location.host.split(".")[0];
      formData["lang"] = this.lang;
      if (type) formData = { ...formData, ...type };
      Axios.request_GET(
        API_PUBLIC_GET_CAMPAIGN_DETAILS,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
    getCampaignSearchList() {
      const _this = this;
      const successHandler = (res) => {
        // console.log("Search List: ", res.data);
        _this.searchList = res.data.search_list;
        _this.searchLoading = false;
      };
      const failureHandler = (res) => {
        console.log("Failure: ", res);
        _this.searchLoading = false;
      };
      let formData = {};
      formData[SEARCH] = this.searchQuery;
      formData[CAMPAIGN_ID] = this.campaign[CAMPAIGN_DETAIL][ID];
      Axios.request_GET(
        API_PUBLIC_MASTER_SEARCH,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
    debounceGetCampaignData: _.debounce(function () {
      this.getCampaignSearchList();
    }, 500),
    publicDonationPageType() {
      let obj = {};
      if (this.$route.query[SCHOOL]) {
        obj.type = SCHOOL;
        obj.school_id = this.$route.query[SCHOOL];
      } else if (this.$route.query[STUDENT]) {
        obj.type = STUDENT;
        obj.student_id = this.$route.query[STUDENT];
      } else if (this.$route.query[TEACHER]) {
        obj.type = TEACHER;
        obj.teacher_id = this.$route.query[TEACHER];
      } else obj = {};
      return _.isEmpty(obj) ? false : obj;
    },

    searchItemClickHandler(item) {
      // let subDomain = window.location.host.split('.')[0];
      let subDomain = "";
      let queryParam = `${item.user_type}=${item.id}`;
      console.log("clicker",queryParam);
      // console.log("subDomain: ", subDomain, "queryParam: ", queryParam);
      Helper.openPublicDonationPage(subDomain, queryParam);
    },
    /**
     * function returns user's initials
     * param {string}  user's full name
     **/
    getInitials(name) {
      let initials = name.split(" ");
      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = name.substring(0, 2);
      }
      return initials.toUpperCase();
    },
    onClickLogoutHandler() {
      authToken.removeAccessToken();
      this.updateUserDetails({});
      this.$router.push({
        name: ROUTER_URL.publicDonationPages.name,
        query: { ...this.$route.query },
      });
    },
    goToDashboardHandler() {
      this.$router.push({
        name: ROUTER_URL.publicParentDashboard.name,
        query: { ...this.$route.query },
      });
    },
    openStudentCreate() {
      this.toggleCreateStudentModal({ show: true, actionType: "add" });
    },
    getStudentDetail() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        this.toggleCreateStudentModal({ show: false });
        console.log(data);
        self.studentFirstName = data.student_detail.student_first_name;
        self.studentLastName = data.student_detail.student_last_name;
        self.parentName = data.student_detail.parent_name;
        self.parentEmail = data.student_detail.parent_email;
        self.studentGoal = data.student_detail.student_goal;
        self.grade = data.student_detail.teacher;
        self.jerseySize = data.student_detail.jersey_size;
        // self.shortSize = data.student_detail.shorts_size;
        self.specialMessage = data.student_detail.student_special_message;
        this.campaign_type = data.student_detail.campaign_type;
        this.alertmessagepopupshow = data.student_detail.share_intimation;
        localStorage.setItem("parentemail", self.parentEmail);
      };
      const failureHandler = (res) => {
        const data = res.data;
        console.log(data);
      };

      let formData = {};
      formData["student_id"] = this.studentID;
      console.log(formData);

      Axios.request_GET(
        API_ADMIN_STUDENT_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
  },
  mounted() {
  console.log("🧭 inside redirectToParentDashboardFlag on mount:", this.redirectToParentDashboardFlag);
  console.log("🧭 inside selectStudentFlag on mount:", this.selectStudentFlag);

  
    if (
      this.$route.name === ROUTER_URL.publicParentDashboard.name &&
      this.$route.query.token
    ) {
      try {
        // Save token in local storage
        localStorage.setItem("token", this.$route.query.token);
      } catch (e) {
        console.log(e);
      }
      let query = Object.assign({}, this.$route.query);
      delete query.token;
      this.$router.replace({ query });
    }
    console.log(_.isEmpty(authToken.decodedToken()));
    if (!_.isEmpty(authToken.decodedToken())) {
      this.updateUserDetails({ user: authToken.decodedToken() });
    } else if (
      this.$route.name === ROUTER_URL.publicParentDashboard.name &&
      _.isEmpty(authToken.decodedToken())
    ) {
      let query = Object.assign({}, this.$route.query);
      this.$router.push({
        name: ROUTER_URL.publicDonationPages.name,
        query,
      });
    }
    this.getCampaignDetails();
    // console.log("userDetails------>", this.userDetails.last_updated);
    //alert(this.$route.name);
    // if(this.$route.name == 'publicParentDashboard') {
    //  setTimeout(() => {
    //   this.toggleAlertMessageModal({ show: true });
    //  }, 1000);
    // }

    if (this.studentID) {
      if (
        this.$route.name == "publicParentDashboard" &&
        !this.alertmessagepopupshow
      ) {
        this.getStudentDetail();
        setTimeout(() => {
          this.toggleAlertMessageModal({ show: true });
        }, 3000);
      }
    }
    //   location.reload();
  },
  created() {},
};
</script>

<style scoped>
.appbar {
  width: 100vw;
}
* {
  font-family: Lato;
}
.appBarTitle {
  font-size: 22px;
  font-weight: 700;
  /* padding-top: 20px; */
}
.v-btn >>> span {
  color: #2c1963;
  font-weight: 700;
  font-size: 17px;
  font-family: Roboto Slab;
}
.footerParagraph {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: white;
  line-height: 108.5%;
  margin-top: 13px;
}
.footerIcon {
  margin-left: 30px;
}
.footerText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 161.5%;
  /* or 45px */
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 40px;
}
.vl {
  border-left: 2px solid #a691e4;
  height: 300px;
  margin-top: 20px;
}
.hl {
  border-top: 2px solid #a691e4;
  width: 100%;
  margin-top: 20px;
}
.footerLinks {
  color: white;
  font-family: Roboto Slab;
}
.borderLinksContent {
  font-size: 20px;
  font-weight: 500;
}
a.link {
  color: white;
}
.spacing {
  margin-top: 28px;
}
.infoColumn {
  display: flex;
}
.no-padding {
  padding: 0 !important;
}

/* Default layout: Keep items on one row */
.responsive-list-item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.responsive-content {
  flex: 1;
}

/* Adjustments for small screens */
@media (max-width: 600px) {
  .responsive-list-item {
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .responsive-action {
    align-self: flex-end;
    margin-top: 10px;
  }

  .responsive-avatar {
    width: 30px;
    height: 30px;
  }

  .searchList-title {
    font-size: 16px;
  }

  .searchList-secondryText {
    font-size: 14px;
  }

  .responsive-chip {
    font-size: 14px !important;
  }
}
.img_container {
  height: auto;
  width: 100vw;
  max-width: 100vw;
  object-fit: contain;
}
.searchList-title {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.searchList-secondryText {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 161.5%;
  color: #757575;
}
.student {
  background: rgba(0, 133, 255, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #0085ff !important;
  text-transform: capitalize;
}
.teacher {
  background: rgba(56, 167, 55, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #38a737 !important;
  text-transform: capitalize;
}
.school {
  background: rgba(45, 45, 45, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #0085ff !important;
  text-transform: capitalize;
}
.appBarBottomImg {
  object-fit: contain;
}
.HarlemFooterImage {
  width: 250px;
}
.switchStudentPage-btn {
  border: 0.5px dashed #ffffff;
  border-radius: 8px;
  background: #341d75;
}
.switchStudentPage-btn span {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 215%;
  color: #ffffff;
  letter-spacing: 0px;
  text-transform: none;
}
.logout-btn span {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 161.5%;
  color: #ffffff;
  letter-spacing: 0px;
  text-transform: none;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  * {
    /* overflow-x: hidden; */
  }
  .footerIcon {
    width: 30px;
    margin-left: 20px;
  }
  .HarlemFooterImage {
    width: 190px;
  }
  .footerText {
    margin-top: 10px;
    font-size: 15px;
  }
  .borderLinksContent {
    font-size: 15px;
  }
  .contactImg {
    min-width: 40px;
  }
  .appBarTitle {
    font-size: 18px;
    padding-top: 15px;
  }
  .v-btn >>> span {
    color: #2c1963;
    font-weight: 700;
    font-size: 14px;
    font-family: Roboto Slab;
  }
}
</style>
